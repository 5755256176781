import React, { ReactNode } from 'react';
import NextLink from 'next/link';
import { connect } from 'react-redux';
import {
  Modal,
  Fade,
  Backdrop,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import ContactForm from '../../../components/molecules/ContactForm';
import ValuationForm from '../../../components/molecules/ValuationForm';

interface LinkProps {
  children: ReactNode;
  data: string;
  setButtonName: (name: string) => void;
  entries: any[];
}

const Link = (props: LinkProps) => {
  const [open, setOpen] = React.useState(false);
  const [isForm, setIsForm] = React.useState('no');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { children, data, setButtonName, entries } = props;

  const [linkUrl, setLinkUrl] = React.useState('');
  React.useEffect(() => {
    const res = entries.find((entry) => entry.sys.id === data);
    setButtonName(res.fields.title);
    const linkType: 'linkInternal' | 'linkExternal' =
      res.sys.contentType.sys.id === 'linkInternal'
        ? 'linkInternal'
        : 'linkExternal';
    if (linkType === 'linkExternal') setLinkUrl(res.fields.url);
    else {
      if (res.fields.pageLink) {
        const pageLink = entries.find(
          (entry) => entry.sys.id === res.fields.pageLink.sys.id
        );
        setLinkUrl(pageLink.fields.slug);
      } else if (res.fields.anchor === 'contact') {
        setIsForm('contact');
      } else if (res.fields.anchor === 'valuation') {
        setIsForm('valuation');
      } else setLinkUrl('#');
    }
  }, []);

  return <>
    {isForm !== 'no' ? (
      <>
        <Box onClick={handleOpen}>{children}</Box>

        <Dialog maxWidth="lg" onClose={handleClose} open={open}>
          <Box
            sx={{
              width: '600px',
              height: '400px',
              px: '20px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir-Bold',
                fontSize: '24px',
                textTransform: 'uppercase',
                mt: '30px',
              }}
            >
              {isForm === 'contact' ? `Kontakt` : `Gratis värdering`}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {isForm === 'contact' ? <ContactForm /> : <ValuationForm />}
          </Box>
        </Dialog>
      </>
    ) : (
      <NextLink href={linkUrl} legacyBehavior>{children}</NextLink>
    )}
  </>;
};
const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(Link);
