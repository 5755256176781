import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import { loadAsset } from '../../api';
// import { Asset } from 'contentful';
import Button from '../Button';
import { connect } from 'react-redux';
import Image from "next/image";

const Hero = (props: {
  heroTitle: string;
  heroText: string;
  heroButtons: any;
  heroImage: any;
  assets: any[];
  entries: any[];
}) => {
  const { heroTitle, heroText, heroButtons, heroImage, assets } = props;

  const asset = assets.find((asset) => asset.sys.id === heroImage.sys.id)

  if (!asset) {
    return null
  }

  // const [loading, setLoading] = React.useState(true);
  const [heroImageUrl, setHeroImageUrl] = React.useState('');
  React.useEffect(() => {
    if (heroImage)
      setHeroImageUrl(
        assets.find((asset) => asset.sys.id === heroImage.sys.id).fields.file
          .url
      );
  }, [assets, heroImage]);

  return (
    <Box
      component="div"
      sx={{
        alignItems: 'center',
        height: { md: '512px', xs: '394px' },
        width: '100%',
        display: 'flex',
        marginBottom: '30px',
        position: "relative"
      }}
    >
      <Image
        src={`https:${assets.find((asset) => asset.sys.id === heroImage.sys.id).fields.file.url}`}
        layout={"fill"}
        objectFit={"cover"}
        priority={true}
        alt=""
      />
      <Container maxWidth="lg">
        <Box
          sx={{
            zIndex: '10',
            my: 'auto',
            marginLeft: '16px',
            position: "relative"
          }}
        >
          <Typography
            component="h1"
            className="font-Avenir-Regular"
            sx={{
              fontSize: { md: '64px', xs: '32px' },
              maxWidth: { xs: '330px', md: '600px' },
              color: 'white',
              fontWeight: { md: 900, xs: 800 },
              lineHeight: { md: '76px', xs: '37px' },
              marginBottom: '16px',
              textShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.65);',
              whiteSpace: 'break-spaces'
            }}
          >
            {heroTitle}
          </Typography>
          <Typography
            component="p"
            className="font-Avenir-Regular"
            sx={{
              maxWidth: { md: '600px', xs: '241px' },
              fontSize: { md: '30px', xs: '18px' },
              color: 'white',
              marginBottom: { md: '55px', xs: '30px' },
              lineHeight: { md: '38px', xs: '23px' },
            }}
          >
            {heroText}
          </Typography>

          <Box sx={{ display: 'flex', pl: { md: '3px', xs: '0px' } }}>
            {heroButtons?.map((item, i) => (
              <Button key={i} data={item.sys.id} />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
    assets: state.main.assets,
  };
};

export default connect(mapStateToProps, null)(Hero);
